<template>
  <div class="section">
    <vue-good-table
      max-height="80vh"
      :columns="columns"
      :rows="stocks"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: status -->
        <span v-if="props.column.field === 'status'">
          <span class="text-capitalize">
            {{ props.row.status }}
          </span>
        </span>

        <!-- Column: note -->
        <span v-else-if="props.column.field === 'note'">
          <div class="text-capitalize note">
            {{ props.row.note || '-' }}
          </div>
        </span>

        <!-- Column: Nama Produk -->
        <span v-else-if="props.column.field === 'product'">
          <template v-for="(item, index) in props.row.items">
            <div :key="index">
              {{ item.product ? item.product.name : '-' }}
            </div>
          </template>
        </span>

        <!-- Column: qty -->
        <span v-else-if="props.column.field === 'qty'">
          <template v-for="(item, index) in props.row.items">
            <div :key="index">
              {{ item.qty }}
            </div>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="goToDetail(props.row.uuid)">
                <span>
                  Lihat Detail
                </span>
              </b-dropdown-item>
              <span v-if="checkPermission('hapus stock opname')">
                <b-dropdown-item @click="deleteData(props.row.uuid)" v-if="props.row.status == 'pending'">
                  <span>
                    Hapus
                  </span>
                </b-dropdown-item>
              </span>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormSelect, VBToggle, BRow, BCol, BCollapse
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BRow,
    BCol,
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  props: {
    result: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    getDataApproval: {
      type: Function,
    },
    getDetail: {
      type: Function
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Tanggal',
          field: 'date',
        },
        // {
        //   label: 'SKU Produk',
        //   field: 'product.sku_code',
        // },
        {
          label: 'Nama Produk',
          field: 'product',
        },
        {
          label: 'Jumlah',
          field: 'qty',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Catatan',
          field: 'note',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      stocks: [],
      filter: {
        per_page: 10,
      },
      itemDetail: {},
    }
  },
  watch: {
    result: {
      handler(value) {
        this.rows = value
        this.stocks = value.data
      },
      deep: true,
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.rows = this.result || {}
    this.stocks = this.result.data || []
  },
  methods: {
    async deleteData(uuid) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin untuk menghapus stok gudang ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('stockOpname/deleteData', {
            uuid,
          })
            .then(() => {
              this.getData()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Stok opname berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    goToDetail(uuid) {
      this.$store.commit('stockOpname/setIsDetail', true)
      // this.$store.commit('stockOpname/setDetailItem', item)
      this.getDetail(uuid)
      this.$bvModal.show('modal-detail-stock-opname')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  height: 71.5vh;
  background-color: #fff;
}
</style>
