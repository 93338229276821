<template>
  <div class="ml-2">
    <div class="d-flex mb-1 mt-2">
      <b-img
        width="16"
        :src="require('@/assets/images/icons/papper.svg')"
      />
      <h6 class="text-dark text-darken-4 fw-bold-600 mb-0 size14 ml-1">
        SKU
      </h6>
    </div>
    <h4 class="text-dark fw-bold-600 size18 mb-1">
      {{ result.sku_code || '-' }}
    </h4>
    <h5 class="text-primary text-dark fw-bold-700 size16 mb-1">
      Stok tersedia: {{ result.total_stock || 0 }}
    </h5>
    <div class="d-flex justify-content-between mb-1 flex-column" v-if="result.stock_in_warehouse">
      <!-- Stok di Gudang -->
      <!-- <h6 class="fw-bold-600 text-dark">Stok di Lokasi SKU</h6> -->
      <div class="d-flex justify-content-between mb-1"
        v-for="warehouse in result.stock_in_warehouse"
        :key="warehouse.uuid"
        v-if="warehouse.type !== 'supplier'"
      >
        <span
          class="text-dark text-darken-4 size14 fw-bold-500 text-capitalize"
          style="width: 35%;"
        >{{ warehouse.name }}</span>
        <div
          class=""
          style="width: 47%;"
        >
          <h6 class="text-dark size14 fw-bold-500 mb-0">
            {{ warehouse.stock }}
          </h6>
        </div>
        <div />
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <span class="text-dark text-darken-4 size14 fw-bold-500">Terakhir stok opname</span>
      <div class="mr-5 pr-5">
        <h6 class="text-dark size14 fw-bold-500">
          {{ result.latest_stock_opname || '-' }}
        </h6>
        <!-- <a
          href="#"
          class="text-dark text-darken-4 size14 fw-bold-500"
        >
          <u>
            Lihat riwayat
          </u>
        </a> -->
      </div>
      <div />
    </div>
    <!-- Rincian -->
    <h4 class="text-dark fw-bold-600 size18 mb-1 mt-2">
      Rincian
    </h4>
    <hr>
    <div class="d-flex justify-content-between mb-1">
      <!-- Nama alias -->
      <span class="text-dark text-darken-4 size14 fw-bold-500" style="width: 35%;">Nama Alias</span>
      <div class="" style="width: 47%;">
        <h6 class="text-dark size14 fw-bold-500 mb-0">
          {{ result.alias_name || '-' }}
        </h6>
      </div>
      <div />
    </div>
    <div class="d-flex justify-content-between mb-1">
      <!-- Merk -->
      <span class="text-dark text-darken-4 size14 fw-bold-500" style="width: 35%;">Merk</span>
      <div class="" style="width: 47%;">
        <h6 class="text-dark size14 fw-bold-500 mb-0">
          {{ result.brand && result.brand.name || '-' }}
        </h6>
      </div>
      <div />
    </div>
    <div class="d-flex justify-content-between mb-1">
      <!-- SKU Pabrik -->
      <span class="text-dark text-darken-4 size14 fw-bold-500" style="width: 35%;">SKU Pabrik</span>
      <div class="" style="width: 47%;">
        <h6 class="text-dark size14 fw-bold-500 mb-0">
          {{ result.sku_code || '-' }}
        </h6>
      </div>
      <div />
    </div>
    <div class="d-flex justify-content-between mb-1">
      <!-- Jenis Barang -->
      <span class="text-dark text-darken-4 size14 fw-bold-500" style="width: 35%;">Jenis Barang</span>
      <div class="" style="width: 47%;">
        <h6 class="text-dark size14 fw-bold-500 mb-0">
          {{ result.item && result.item.name || '-' }}
        </h6>
      </div>
      <div />
    </div>
    <div class="d-flex justify-content-between mb-1">
      <!-- Spesifikasi -->
      <span class="text-dark text-darken-4 size14 fw-bold-500" style="width: 35%;">Spesifikasi</span>
      <div class="" style="width: 47%;">
        <h6 class="text-dark size14 fw-bold-500 mb-0">
          {{ result.specification || '-' }}
        </h6>
      </div>
      <div />
    </div>
    <div class="d-flex justify-content-between mb-3">
      <!-- Satuan -->
      <span class="text-dark text-darken-4 size14 fw-bold-500" style="width: 35%;">Satuan</span>
      <div class="" style="width: 47%;">
        <h6 class="text-dark size14 fw-bold-500 mb-0">
          {{ result.uom && result.uom.name || '-' }}
        </h6>
      </div>
      <div />
    </div>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  watch: {
    '$store.state.stockOpname.resultProduct': {
      handler(newValue) {
        this.result = newValue;
      },
      immediate: true,
      deep: true,
    },
    '$store.state.stockOpname.selectedWarehouse': {
      handler(value) {
        if (value && value.length > 0) {
          this.result.total_stock = value[0].stock || 0;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      result: {},
      stock: 0,
    }
  },
}
</script>
